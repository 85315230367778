import React from "react";
import { Card, CardActionArea, CardContent, Grid } from "@mui/material";
import '../App.css';

export const PublicHealth = ({}) => {
  return (
    <Grid className="centered-content">
        <div className="google-doc-iframe-container">
          <iframe className="google-doc-iframe" width="100%" height="100%" src="https://docs.google.com/document/d/e/2PACX-1vQ-ekjmnS5wpPdjtx9xurTjfd3eyvK2TLJIoYkAG6noJFyA_4OEtrM33sKLnMBzbZ1yequTeMlb2Jg1/pub?embedded=true"/>
        </div>
    </Grid>
  )
}
