import React from "react";
import { Card, CardActionArea, CardContent, Grid } from "@mui/material";
import '../App.css';

export const Mindfulness = ({}) => {
  return (
    <Grid className="centered-content">
        <div className="google-doc-iframe-container">
          <iframe className="google-doc-iframe" width="100%" height="100%" src="https://docs.google.com/document/d/e/2PACX-1vQxGAlw_CAaz2Qg7wy_whsf7ksRBCcmT2Fk8OIqsV838bkTpbFceR7MHbi3aqZe_o0JxK5kKpUoNQnp/pub?embedded=true"/>
        </div>
    </Grid>
  )
}
