import React from "react";
import { Card, CardActionArea, CardContent, Grid } from "@mui/material";
import '../App.css';

export const Keto = ({}) => {
  return (
    <Grid className="centered-content">
        <div className="google-doc-iframe-container">
          <iframe className="google-doc-iframe" width="100%" height="100%" src="https://docs.google.com/document/d/e/2PACX-1vSfYVnQhxendPLFt-k80znpoBIFYZDZrlQ3-xD4ESN5De1U71lDlKM_8gGP-0y8mVA9qToQJjOE1_7j/pub?embedded=true"/>
        </div>
    </Grid>
  )
}
