import './App.css';
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { Home } from "./pages";
import { Keto, Mindfulness, PublicHealth } from "./writing";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/writing/case-for-normalizing-keto" element={<Keto />} />
      <Route path="/writing/making-mindfulness-your-default" element={<Mindfulness />} />
      <Route path="/writing/cargo-cults-supply-chains-personal-failure-public-health" element={<PublicHealth />} />
      <Route path="/*" element={<Navigate to="/" />} />
    </Routes>
  );
}

export default App;
